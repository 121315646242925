import LoadingGif from './../img/loading.gif';
import './Loading.css'

export function Loading() {



    return (

        
            <div className='loading'>                
                <img src={LoadingGif} alt="loading" />
            </div>
        
    )
}




