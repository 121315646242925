import { ContactForm } from '../components/ContactForm';


export function Contacto() {

    return (
       <div>
        <h1>Enviame un mensaje!!</h1>
        <ContactForm />
       </div>
    )


}