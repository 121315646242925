import { Banner } from '../components/Banner'
import { Grid } from '../components/Grid'
import '../components/Grid.css'



export function Gallery() {

       return (
        <div>
            <h1>Mis dibujos</h1>     
            <Banner />      
            <Grid />               

        </div>
    )
}
