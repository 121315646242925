import { Detalles } from "../components/Detalles";


export function Detalle() {
    return (
        <>
          <h1>Detalles de la obra</h1>          
          <Detalles /> 
            
        </>
            
        
    )
}
