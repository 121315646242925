import { AddForm } from '../components/AddForm';


export function Add() {

    return (
       <div>
        <h1>Sube dibujo nuevo</h1>
        <AddForm />
       </div>
    )


}