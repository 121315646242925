import { Profile } from "../components/Profile";


export function Perfil() {
    return (
        <div>
            <h1>Hola, soy Dahlia!</h1>
            <Profile />
        </div>

    )
}
